




















































import {Component, Vue} from 'vue-property-decorator';
import {CombinationCompilation} from '@/types';
import {CombinationService} from '@/api';
import UBlock from '@/ui-lib/block/Block.vue';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import UButton from '@/ui-lib/button/Button.vue';
import {compilation} from '@/store/modules/compilation';
import {CompilationProbability} from '@/components/track/select-probabilities/TrackSelectProbabilities.vue';

@Component<CombinationCompilationsView>({
    components: {
        UBlock,
        UTable,
        UButton,
    },

    metaInfo: {
        title: 'Подборки',
    },

    async beforeRouteEnter(to, from, next) {
        try {
            const combinationId = parseInt(to.params.id, 10);
            const compilations = await CombinationService.compilations(combinationId);

            next((vm) => {
                vm.compilations = compilations;
            });
        } catch (e) {
            next(e);
        }
    },
})
export default class CombinationCompilationsView extends Vue {
    private compilations: CombinationCompilation[] = [];

    private get fields(): TableField[] {
        return [
            {
                name: 'compilationId',
                label: 'ID',
            },

            {
                name: 'name',
                label: 'Подборка',
            },

            {
                name: 'probability',
                label: 'Вероятность',
            },

            {
                name: 'ipp',
                label: 'ИПР (от 100%)',
            },

            {
                name: 'trackCount',
                label: 'Всего треков',
                align: 'right',
            },
        ];
    }

    private get compilationsList() {
        return this.compilations.sort((a, b) => {
            return b.probability - a.probability;
        });
    }

    private get compilationsBase() {
        return compilation.context(this.$store);
    }

    private name(item: CombinationCompilation) {
        return this.compilationsBase.state.list.find((c) => c.id === item.compilationId)?.name || '';
    }

    private get sumProbabilities() {
        return this.compilations.reduce((a, c) => a + c.probability, 0);
    }

    private ipp(item: CompilationProbability): string {
        const p = Math.round(item.probability / this.sumProbabilities * 100);

        return `${p}%`;
    }
}
